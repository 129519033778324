import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { updateProduct } from "../../../Redux/Slices/ProductSlice";

const UpdateProduct = () => {
  const dispatch = useDispatch();

  const [product_name, setProduct_name] = useState("");
  const [product_price, setProduct_price] = useState("");
  const [product_decription, setProduct_decription] = useState("");
  const [restaurantId, setRestaurantId] = useState("");
  const [images, setImages] = useState("");

  const { id } = useParams();

  const navigate = useNavigate();
  useEffect(() => {
    const getSingleProduct = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/product/find-product/${id}`
      );
      if (response) {
        setProduct_name(response.data.product_name);
        setProduct_price(response.data.product_price);
        setProduct_decription(response.data.product_decription);
        setRestaurantId(response.data.restaurantId);
      }
    };
    getSingleProduct();
  }, [id]);

  const formData = new FormData()

  formData.append('product_name', product_name)
  formData.append('product_price', product_price)
  formData.append('product_decription', product_decription)
  formData.append('restaurantId',restaurantId)
  formData.append('images', images)

  const handleOnSubmit = (e) => {
    e.preventDefault();
    dispatch(updateProduct({id: id,formData: formData}));
    setProduct_name("");
    setProduct_decription("");
    setProduct_price("");
    setTimeout(() => {
      navigate("/dashboard/show-products");
    }, 800);
  };

  return (
    <div style={{ marginTop: "150px", marginLeft: "150px" }}>
      <form className="row g-3" onSubmit={handleOnSubmit}>
        <div className="col-md-6">
          <label htmlFor="rest_name" className="form-label">
            Product Name
          </label>
          <input
            value={product_name}
            className="form-control"
            onChange={(e) => setProduct_name((pre) => e.target.value)}
            id="rest_name"
            required
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="rest_type" className="form-label">
            Product Price
          </label>
          <input
            value={product_price}
            onChange={(e) => setProduct_price((pre) => e.target.value)}
            type="text"
            className="form-control"
            id="rest_type"
            required
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="rest_desc" className="form-label">
            Product Decription
          </label>
          <input
            value={product_decription}
            type="text"
            className="form-control"
            onChange={(e) => setProduct_decription((pre) => e.target.value)}
            id="rest_desc"
            required
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="res_img" className="form-label">
            Choose Image
          </label>
          <input
            required
            autoComplete="off"
            name="images"
            type="file"
            className="form-control"
            id="res_img"
            onChange={e => setImages(pre => e.target.files[0])}
          />
        </div>
        <div className="col-12">
          <button type="submit" className="btn btn-primary">
            Update Product
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdateProduct;
