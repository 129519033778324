import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

let userData = localStorage.getItem("userdata") || null;
userData = userData ? JSON.parse(userData) : null;

const initialState = {
  userAddress: [],
  userLatLng: [],
  userCurrentAddress: [],
  isAuthUser: userData || false,
  userDetails: userData || {},
  saveAddress: false,
  userIsSignup: false,
  updateAddress: false
};
const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const userRegistraion = createAsyncThunk(
  "user/userRegistration",
  async (data) => {
    const response = await axios.post(`${baseUrl}/user/create-user`, data);
    if (response.data.success) {
      toast(response.data.message);
    } else {
      toast(response.data.message);
    }
    return response.data;
  }
);

export const addUserCurrentAddress = createAsyncThunk(
  "user/addUserCurrentAddress",
  async (data) => {
    const response = await axios.post(
      `${baseUrl}/address/create-address`,
      data
    );
    if (response.data.isSaved) {
      toast(response.data.message);
    } else {
      toast(response.data.message);
    }
    return response.data;
  }
);

export const getUserAddress = createAsyncThunk(
  "user/getUserAddress",
  async (userId) => {
    try {
      const response = await axios.get(
        `${baseUrl}/address/get-address/${userId}`
      );
      if (response) {
        toast(response.data.message);
      }
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const updateUserAddress = createAsyncThunk(
  "address/updateUserAddress",
  async ({ id: id, data: data }) => {
    const response = await axios.patch(
      `${baseUrl}/address/update-address/${id}`,
      data
    );
    if (response) {
      toast(response.data.message);
    }
    return response.data;
  }
);

export const signInWithGoogle = createAsyncThunk(
  "user/signWithGoogle",
  async () => {
    window.open(await axios.get(`${baseUrl}/auth/google`));
  }
);

const UserSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    userAddress: (state, action) => {
      state.userAddress = action.payload;
    },
    userLatLan: (state, action) => {
      state.userLatLng = action.payload;
    },
    userLogin: (state, action) => {
      state.isAuthUser = true;
      state.userDetails = action.payload;
    },
    userLogout: (state) => {
      state.isAuthUser = false;
      state.userDetails = {};
    },
    isSaveAddress: (state) => {
      state.saveAddress = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserAddress.fulfilled, (state, action) => {
      state.userCurrentAddress = action.payload;
    });
    builder.addCase(userRegistraion.fulfilled, (state, action) => {
      state.userIsSignup = true;
    });
    builder.addCase(addUserCurrentAddress.fulfilled, (state, action) => {
      state.saveAddress = true;
    });
    builder.addCase(updateUserAddress.fulfilled, (state, action) => {
      state.updateAddress = true
    })
  },
});

export const userAction = UserSlice.actions;
export const userReducer = UserSlice.reducer;
