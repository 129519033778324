import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./userlogin.css";
import { useDispatch } from "react-redux";
import { userAction } from "../../Redux/Slices/User";
import GoogleAuth from "../GoogleAuth/GoogleAuth";
import FacebookAuth from "../FacebookAuth/FacebookAuth";

const UserLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const data = {
    email,
    password,
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user/login-user`,
        data
      );
      if (response.data.success) {
        toast(response.data.message);
        const obj = {
          username: response.data.data.username,
          email: response.data.data.email,
          userId: response.data.data.id,
          token: response.data.token,
        };
        dispatch(userAction.userLogin(obj));
        navigate("/");
        localStorage.setItem("userdata", JSON.stringify(obj));
      } else {
        toast(response.data.message);
      }
      return response.data;
    } catch (error) {
      return error;
    }
  };

  return (
    <div>
      <div className="container-fluid px-1 px-md-5 px-lg-1 px-xl-5 py-5 mx-auto userlogin">
        <form className="card card0 border-0" onSubmit={handleLogin}>
          <h2>Sign in</h2>
          <div className="card2 card border-0 px-4 py-5">
            <div className="row input_row px-3">
              <label className="mb-1">
                <h6 className="mb-0 text-sm">Email Address</h6>
              </label>
              <input
                autoComplete="off"
                onChange={(e) => setEmail((pre) => e.target.value)}
                className="mb-4"
                type="text"
                name="email"
              />
            </div>
            <div className="row input_row px-3">
              <label className="mb-1">
                <h6 className="mb-0 text-sm">Password</h6>
              </label>
              <input
                onChange={(e) => setPassword((pre) => e.target.value)}
                autoComplete="off"
                type="password"
                name="password"
              />
            </div>
            <div className="row input_row px-3 mb-4">
              <p className="ml-auto mb-0 text-sm">Forgot Password?</p>
            </div>
            <div className="row input_row mb-3 px-3">
              <button type="submit" className="btn btn-blue text-center">
                Login
              </button>
            </div>
            <div className="row input_row px-3">
              <p>OR</p>
            </div>
            <div className="col input_row mb-3">
              <GoogleAuth />
              <br />
              {/* <FacebookAuth /> */}
            </div>{" "}
            <div className="row input_row mb-4 px-3">
              <small className="font-weight-bold">
                Don't have an account?{" "}
                <Link to="/user/registration" className="text-danger ">
                  Register
                </Link>
              </small>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserLogin;
