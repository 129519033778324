import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { deleteRestarunt } from "../../../Redux/Slices/Restaurant";
import Loader from "../../Loader/Loader";

const DeleteData = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(deleteRestarunt(id));
    setTimeout(() => {
      navigate("/dashboard/show-restaurants");
    }, 1000);
  }, []);

  return (
    <div>
        <Loader/>
    </div>
  );
};

export default DeleteData;
