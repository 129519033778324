import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  updateRestaurantData,
} from "../../../Redux/Slices/Restaurant";
import DashboardMap from "../DashboardMap/DashboardMap";
import "./updatedata.css";
const UpdateData = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const navigate = useNavigate()
  const [restaurant_name, setRestaurant_name] = useState("");
  const [restaurant_type, setRestaurant_type] = useState("");
  const [restaurant_decription, setRestaurant_decription] = useState("");
  const [images, setImages] = useState("");
  const [restaurant_latitude, setRestaurant_latitude] = useState("");
  const [restaurant_longitude, setRestaurant_longitude] = useState("");
  
  const resLatLng = useSelector((state) => state.admin.resLatLng);
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/restaurant/get-restaurant/${id}`
      )
      .then((response) => {
        return response.data;
      })
      .then((item) => {
        setRestaurant_name(item.restaurant_name);
        setRestaurant_type(item.restaurant_type);
        setRestaurant_decription(item.restaurant_decription);
      });
    }, [id]);
    
    useEffect(() => {
      setRestaurant_latitude(resLatLng.lat);
      setRestaurant_longitude(resLatLng.lng);
  }, [resLatLng])
  

  const formData = new FormData()
  formData.append('restaurant_name', restaurant_name)
  formData.append('restaurant_type', restaurant_type)
  formData.append('restaurant_decription',restaurant_decription)
  formData.append('images',images)
  formData.append('restaurant_latitude', restaurant_latitude)
  formData.append('restaurant_longitude', restaurant_longitude)

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateRestaurantData({id:id, formData:formData }))
    navigate('/dashboard/show-restaurants')
  };

  return (
    <div className="updatedata">
      <form className="row g-3" onSubmit={handleSubmit}>
        <h3 className="d-flex justify-content-center">Edit Restaurant</h3>
        <hr />
        <div className="col-md-6">
          <label htmlFor="rest_name" className="form-label">
            Restaurants Name
          </label>
          <input
            value={restaurant_name}
            autoComplete="off"
            type="text"
            className="form-control"
            id="rest_name"
            onChange={(e) => setRestaurant_name((pre) => e.target.value)}
            required
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="rest_type" className="form-label">
            Restaurants Type
          </label>
          <input
            value={restaurant_type}
            autoComplete="off"
            type="text"
            className="form-control"
            id="rest_type"
            onChange={(e) => setRestaurant_type((pre) => e.target.value)}
            required
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="latitude" className="form-label">
            Latitude
          </label>
          <input
            value={restaurant_latitude}
            autoComplete="off"
            type="text"
            className="form-control"
            id="latitude"
            onChange={(e) => setRestaurant_latitude((prev) => e.target.value)}
            required
          />
        </div>

        <div className="col-md-6">
          <label htmlFor="longitude" className="form-label">
            Longitude
          </label>
          <input
            value={restaurant_longitude}
            autoComplete="off"
            type="text"
            className="form-control"
            id="longitude"
            onChange={(e) => setRestaurant_longitude(per => e.target.value)}
            required
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="rest_type" className="form-label">
            Restaurants Description
          </label>
          <textarea
            value={restaurant_decription}
            autoComplete="off"
            className="form-control"
            id="rest_type"
            rows="3"
            onChange={(e) => setRestaurant_decription(pre => e.target.value)}
            required
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="res_img" className="form-label">
            Choose Image
          </label>
          <input
            required
            autoComplete="off"
            name="images"
            type="file"
            className="form-control"
            id="res_img"
            onChange={(e) => setImages(pre => e.target.files[0])}
          />
        </div>
        <div className="col-md-12">
          <label htmlFor="res_img" className="form-label">
            Choose your restarunt Latitude Longitude
          </label>
          <DashboardMap />
        </div>
        <div className="col-12" style={{ marginTop: "3rem" }}>
          <button type="submit" className="btn btn-primary">
            Update Restaurants
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdateData;
