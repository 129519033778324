import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { cartAction } from "../../Redux/Slices/Cart";
import "./orderDetails.css";

const OrderDetails = () => {
  const [orderData, setOrderData] = useState([]);
  const [address, setAddress] = useState([]);
  const [product, setProduct] = useState([]);
  const [user, setUser] = useState([]);
  const dispatch = useDispatch();
  const currentuserCreatedOrder = useSelector(
    (state) => state.order.currentuserCreatedOrder
  );
  const { id } = currentuserCreatedOrder;
  useEffect(() => {
    const getData = async () => {
      if (id) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/order/get-order/${id}`
        );
        setOrderData(response.data);
        setProduct(response.data.product);
        setAddress(response.data.address);
        setUser(response.data.user);
      }
    };
    getData();
  }, [id]);

  const { id: productId, product_name } = product;
  const { currentAddress, phone, areaPincode } = address;
  const { id: orderId, totalAmount, totalItem } = orderData;
  const { email } = user;
  return (
    <div className="col-md-6 order_details">
      <div className="card">
        <div className="card-header">
          <div className="d-inline h4">Order Details</div>
          <div className="d-inline float-right">
            <small>
              <p>Edit</p>
            </small>
          </div>
        </div>
        <div className="card-body">
          <dl className="row">
            <dd className="col-sm-4">Order Id</dd>
            <dt className="col-sm-8">{orderId}</dt>
            <dd className="col-sm-4">Product Id</dd>
            <dt className="col-sm-8">{productId}</dt>
            <dd className="col-sm-4">Product Name</dd>
            <dt className="col-sm-8">{product_name}</dt>
          </dl>
          <dl className="row"></dl>
          <dl className="row">
            <dd className="col-sm-4">User Email</dd>
            <dt className="col-sm-8">{email}</dt>
            <dd className="col-sm-4">Address</dd>
            <dt className="col-sm-8">{currentAddress}</dt>
            <dd className="col-sm-4">Phone</dd>
            <dt className="col-sm-8">{phone}</dt>
          </dl>
          <dl className="row">
            <dd className="col-sm-4">Zipcode</dd>
            <dt className="col-sm-8">{areaPincode}</dt>
            <dd className="col-sm-4">Total Item</dd>
            <dt className="col-sm-8">{totalItem}</dt>
            <dd className="col-sm-4">Total Price</dd>
            <dt className="col-sm-8">
              {totalAmount} <br />
              <br />
              <Link to="/oredr-confirn">
                <button
                  onClick={() => dispatch(cartAction.cartEmptyAfterOdering())}
                  className="btn btn-primary"
                >
                  Place Order
                </button>
              </Link>
            </dt>
          </dl>
          <hr />
          <dl className="row">
            <dt className="col-sm-12">Comments</dt>
            <dd className="col-sm-12">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
