import React, { useState } from "react";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { userAction } from "../../Redux/Slices/User";

const GoogleAuth = () => {

  const [userData, setUserData] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const login = useGoogleLogin({
    onSuccess: async (CredentialResponse) => {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user/user-login-google`,
        { email: userData.email, googleId: userData.sub }
      );
      toast(response.data.message);
      const obj = {
        username: userData.name,
        email: userData.email,
        userId: response.data.data.id,
        token: CredentialResponse.access_token,
      };
      dispatch(userAction.userLogin(obj));
      localStorage.setItem("userdata", JSON.stringify(obj));
      if (response.data.loggedin) {
        navigate("/");
      }
    },
    onError: () => {
      toast("Something went wrong");
    },
  });

  return (
    <GoogleLogin
      onSuccess={(CredentialResponse) => {
        setUserData(jwt_decode(CredentialResponse.credential));
        login();
      }}
      onError={() => {
        console.log("Login Failed");
      }}
      width={100}
      size="large"
      ux_mode=""
    />
  );
};

export default GoogleAuth;
