import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { cartReducer } from "./Slices/Cart";
import { adminReducer } from "./Slices/Admin";
import { oredrReducer } from "./Slices/Order";
import { productReducer } from "./Slices/ProductSlice";
import { restaurantReducer } from "./Slices/Restaurant";
import { searchReducer } from "./Slices/search";
import { userReducer } from "./Slices/User";

const persistConfig = {
  key: "persist-cart",
  storage,
  whitelist: ["cart"],
  blacklist: ['product', 'restaurant', 'order', 'search', 'admin', 'user']
  
  
};
const combineReducer = combineReducers({
  cart: cartReducer,
  product: productReducer,
  restaurant: restaurantReducer,
  user: userReducer,
  search: searchReducer,
  admin: adminReducer,
  order: oredrReducer,
});

const persistedReducers = persistReducer(persistConfig, combineReducer);

export const store = configureStore({
  reducer: persistedReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
