import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userRegistraion } from "../../Redux/Slices/User";
import Form from "./Form";
import "./userRegistration.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const UserResgistration = () => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [userData, setUserData] = useState();
  const navigate = useNavigate();

  const data = {
    username,
    email,
    password,
  };
  const { userIsSignup } = useSelector((state) => state.user);
  // console.log("userIsSignup>>", userIsSignup);
  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(userRegistraion(data));
  };
  if (userIsSignup) {
    navigate("/user/login");
  }
  const registration = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user/user-signup-google`,
        {
          email: userData.email,
          username: userData.name,
          googleId: userData.sub,
        }
      );
      toast(response.data.message);
      navigate("/user/login");
    },
  });

  return (
    <section className="vh-80 bg-image user_registration">
      <div className="mask d-flex align-items-center h-100 gradient-custom-3">
        <div className="container h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-9 col-lg-7 col-xl-6">
              <div className="card" style={{ borderRadius: "15px" }}>
                <div className="card-body p-5">
                  <h2 className="text-uppercase text-center mb-5">
                    Create an account
                  </h2>

                  <form onSubmit={handleLogin}>
                    <Form
                      type="text"
                      id="form3Example1cg"
                      divClassName="form-outline mb-4"
                      className="form-control form-control-lg"
                      onChange={(e) => setUsername((pre) => e.target.value)}
                      lable="Username"
                    />
                    <Form
                      type="email"
                      id="form3Example3cg"
                      divClassName="form-outline mb-4"
                      className="form-control form-control-lg"
                      onChange={(e) => setEmail((pre) => e.target.value)}
                      lable="Email"
                    />

                    <Form
                      divClassName="form-outline mb-4"
                      type="password"
                      id="form3Example4cg"
                      className="form-control form-control-lg"
                      onChange={(e) => setPassword((pre) => e.target.value)}
                      lable="Password"
                    />

                    <div className="d-flex">
                      <button
                        type="submit"
                        className="btn btn-block text-light"
                        style={{ background: "#1A237E", width: "10vw" }}
                      >
                        Register
                      </button>
                    </div>
                    <span className="d-flex mx-5 p-3">OR</span>
                    <div className="form-outline mb-4">
                      <GoogleLogin
                        onSuccess={(credentialResponse) => {
                          registration();
                          // console.log(credentialResponse.credential);
                          const decode = jwt_decode(
                            credentialResponse.credential
                          );
                          // console.log("decode Regidtarion>>", decode);
                          setUserData(decode);
                        }}
                        onError={() => {
                          console.log("Login Failed");
                        }}
                        width={100}
                      />
                      <br />
                      {/* <FacebookLogin
                        appId={process.env.REACT_APP_FACEBOOK_KEY}
                        onSuccess={async (response) => {
                          console.log("Login Success!", response);
                          const res = await axios.post(
                            `${process.env.REACT_APP_API_BASE_URL}/user/user-signup-facebook`,
                            {
                              email: userData.email,
                              username: userData.name,
                              facebookId: userData.id,
                            }
                          );
                          toast(res.data.message);
                        }}
                        onFail={(error) => {
                          console.log("Login Failed!", error);
                        }}
                        onProfileSuccess={(response) => {
                          console.log("Get Profile Success!", response);
                          setUserData(response);
                        }}
                        style={{
                          backgroundColor: "#4267b2",
                          color: "#fff",
                          fontSize: "16px",
                          padding: "12px 24px",
                          border: "none",
                          borderRadius: "4px",
                        }}
                      /> */}
                    </div>

                    <p className="text-center text-muted mt-5 mb-0">
                      Have already an account?{" "}
                      <a href="#!" className="fw-bold text-body">
                        <u>Login here</u>
                      </a>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserResgistration;
