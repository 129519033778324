import React from 'react'
import { Link } from 'react-router-dom'

const UserNotAuth = () => {
  return (
    <div className="user_not_loggedin" style={{marginTop:'150px'}}>
        <h2>User is not Logged in</h2>
        <Link to='/admin/login'>GO to login page</Link>
    </div>
  )
}

export default UserNotAuth