import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./dashboardMap.css";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { useDispatch } from "react-redux";
import { adminAction } from "../../../Redux/Slices/Admin";
import styled from "styled-components";

const DashboardMap = () => {
  const center = {
    lat: 21.225473027500747,
    lng: 72.81124662228235,
  };

  const [position1, setPosition1] = useState(center);
  const dispatch = useDispatch();
  const setPosition = (value) => {
    setPosition1(value);
  };

  useEffect(() => {
    dispatch(adminAction.getResLatLng(position1));
  }, [position1]);

  // Draggable Marker function
  function DraggableMarker({ setPosition, position }) {
    const [draggable, setDraggable] = useState(false);
    const markerRef = useRef(null);
    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current;
          if (marker != null) {
            setPosition(marker.getLatLng());
          }
        },
      }),
      []
    );
    const toggleDraggable = useCallback(() => {
      setDraggable((d) => !d);
    }, []);

    return (
      <Marker
        draggable={DraggableMarker}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}
      >
        <Popup minWidth={90}>
          <span onClick={toggleDraggable}>
            {draggable
              ? "Marker is draggable"
              : "Click here to make marker draggable"}
          </span>
        </Popup>
      </Marker>
    );
  }

  // Styled component named StyledButton

  return (
    <StyledComponents>
      <div>
        <MapContainer
          center={center}
          zoom={12}
          scrollWheelZoom={false}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <DraggableMarker setPosition={setPosition} position={position1} />
        </MapContainer>
      </div>
    </StyledComponents>
  );
};

export default DashboardMap;

const StyledComponents = styled.div`
  .leaflet-container{
    margin-top:20px;
    width:50%;
  }
`;
