import React, { useState } from "react";
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
const AdminHeader = ({handleClick}) => {
    const adminData = localStorage.getItem("adminData")
    ? localStorage.getItem("adminData")
    : "";
  const { username } = JSON.parse(adminData);

  const [showDisplay, setShowDisplay] = useState(true);

  const handleShoeMenu = () => {
    handleClick(showDisplay)
    setShowDisplay(!showDisplay)
  }
  return (
    <header className="header body-pd " id="header">
      <div className="header_toggle">
        {" "}
       <MenuOutlinedIcon onClick={handleShoeMenu}/>
      </div>
      {!username ? (
        <div className="header_img">
          <img src="https://i.imgur.com/hczKIze.jpg" alt="" />{" "}
        </div>
      ) : (
        ""
      )}
      {username ? <span>Welcome - {username} </span> : ""}
    </header>
  );
};

export default AdminHeader;
