import React, { useState } from "react";
import "./login.css";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { adminAction } from "../../Redux/Slices/Admin";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const data = {
    email, password
  }
  
  const handleLogin = async (e) => {
    e.preventDefault();
    const userLogin = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/user/login-admin`,data);
    try {
      if (userLogin.data.success === true) {
        toast(userLogin.data.message, {
          theme: "dark",
        });
        localStorage.setItem("token", userLogin.data.token);
        localStorage.setItem('adminData', JSON.stringify(userLogin.data.data))
        dispatch(adminAction.isAdminLoggedIn());
        setTimeout(() => {
          navigate("/dashboard");
        }, 800);
      } else {
        toast(userLogin.data.message, {
          theme: "dark",
        });
      }
    } catch (error) {
      toast(error, {
        theme: "dark",
      });
    }
  };

  return (
    <section className="vh-100 login">
      <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-md-9 col-lg-6 col-xl-5">
            <img
              src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
              className="img-fluid"
              alt="Sample image"
            />
          </div>
          <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
            <form onSubmit={handleLogin}>
              <div className="d-flex flex-row align-items-center justify-content-center justify-content-lg-start signin_title">
                <p className="lead fw-normal me-3">Login </p>
              </div>
              <div className="form-outline mb-4">
                <input
                  type="text"
                  id="form3Example3"
                  className="form-control form-control-lg"
                  placeholder="Email"
                  onChange={(e) => setEmail(pre => e.target.value)}
                />
              </div>
              <div className="form-outline mb-3">
                <input
                  type="password"
                  id="form3Example4"
                  className="form-control form-control-lg"
                  placeholder="Password"
                  onChange={(e) => setPassword( pre => e.target.value)}
                />
              </div>
              <div className="text-center text-lg-start mt-4 pt-2">
                <button
                  type="submit"
                  className="btn btn-primary btn-lg"
                  style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem" }}
                >
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginForm;
