import React from "react";

const Form = ({ type, divClassName, id, className, onChange, lable }) => {
  return (
    <div className={divClassName}>
      <input
        type={type}
        id={id}
        className={className}
        onChange={onChange}
        autoComplete={false}
        required
      />
      <label className="form-label" htmlFor="form3Example1cg">
        {lable}
      </label>
    </div>
  );
};

export default Form;
