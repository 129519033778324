import React, { useEffect, useState } from "react";
import "./ShowRestaurant.css";
import { useDispatch, useSelector } from "react-redux";
import { addRestaurantData } from "../../../Redux/Slices/Restaurant";
import DashboardMap from "../DashboardMap/DashboardMap";
import Loader from "../../Loader/Loader";
import { useNavigate } from "react-router-dom";
const ShowRestaurant = () => {
  const resLatLng = useSelector((state) => state.admin.resLatLng);
  const [restaurant_name, setRestaurant_name] = useState("");
  const [restaurant_type, setRestaurant_type] = useState("");
  const [restaurant_decription, setRestaurant_decription] = useState("");
  const [images, setImages] = useState("");
  const [restaurant_latitude, setRestaurant_latitude] = useState("");
  const [restaurant_longitude, setRestaurant_longitude] = useState("");

  const dispatch = useDispatch();

  const formData = new FormData();
  formData.append("restaurant_name", restaurant_name);
  formData.append("restaurant_type", restaurant_type);
  formData.append("restaurant_decription", restaurant_decription);
  formData.append("images", images);
  formData.append("restaurant_latitude", restaurant_latitude);
  formData.append("restaurant_longitude", restaurant_longitude);

  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addRestaurantData(formData));

    setRestaurant_decription("");
    setRestaurant_name("");
    setRestaurant_type("");
    setRestaurant_latitude("");
    setRestaurant_longitude("");

    setTimeout(() => {
      navigate("/dashboard/show-restaurants");
      return <Loader />;
    }, 1000);
  };
  useEffect(() => {
    setRestaurant_latitude(resLatLng.lat);
    setRestaurant_longitude(resLatLng.lng);
  }, [resLatLng]);

  const handleImageCahnge = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setImages(e.target.files[0]);
    }
  };
  
  return (
    <div className="dashboard">
      <form className="row g-3" onSubmit={handleSubmit}>
        <div className="col-md-12" style={{ textAlign: "center" }}>
          <h2>Add Restaurent</h2> <hr />
        </div>
        <div className="col-md-6">
          <label htmlFor="rest_name" className="form-label">
            Restaurants Name
          </label>
          <input
            value={restaurant_name}
            autoComplete="off"
            type="text"
            className="form-control"
            id="rest_name"
            onChange={(e) => setRestaurant_name((pre) => e.target.value)}
            required
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="rest_type" className="form-label">
            Restaurants Type
          </label>
          <input
            value={restaurant_type}
            autoComplete="off"
            type="text"
            className="form-control"
            id="rest_type"
            onChange={(e) => setRestaurant_type((pre) => e.target.value)}
            required
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="latitude" className="form-label">
            Latitude
          </label>
          <input
            value={restaurant_latitude}
            autoComplete="off"
            type="text"
            className="form-control"
            id="latitude"
            onChange={(e) => setRestaurant_latitude((prev) => e.target.value)}
            required
          />
        </div>

        <div className="col-md-6">
          <label htmlFor="longitude" className="form-label">
            Longitude
          </label>
          <input
            value={restaurant_longitude}
            autoComplete="off"
            type="text"
            className="form-control"
            id="longitude"
            onChange={(e) => setRestaurant_longitude(e.target.value)}
            required
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="rest_type" className="form-label">
            Restaurants Description
          </label>
          <textarea
            value={restaurant_decription}
            autoComplete="off"
            className="form-control"
            id="rest_type"
            rows="3"
            onChange={(e) => setRestaurant_decription(e.target.value)}
            required
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="res_img" className="form-label">
            Choose Image
          </label>
          <input
            required
            autoComplete="off"
            name="images"
            type="file"
            className="form-control"
            id="res_img"
            onChange={handleImageCahnge}
          />
          {
            images ? 
            <img src={URL.createObjectURL(images)} style={{width:'10vw'}} alt="" /> :
            ''
          }
        </div>
        <div className="col-md-12 __mapRes">
          <label htmlFor="map" className="form-label">
            Choose your restarunt Latitude Longitude
          </label>
          <DashboardMap />
        </div>
      
   
        <div className="col-12 my-4" style={{ marginTop: "3rem"}}>
          <button type="submit" style={{width:'20vw'}} className="btn btn-primary">
            Add Restaurants
          </button>
        </div>
      </form>
    </div>
  );
};

export default ShowRestaurant;
