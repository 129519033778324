import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    products: [],
    foodProduct:[]
}

export const fetchSearchProduct = createAsyncThunk('search/fetchSearchProduct', async(sTerm) => {
    if(sTerm !== ''){
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/search/search-data/${sTerm}`)
        // console.log('sTerm-yes>>>>>', response.data)
        return response.data
    }else{
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/restaurant/get-restaurants`)
        // console.log('sTerm-not>>>>>', response.data)
        return response.data
    }
})


export const fetchSearchFoodProduct = createAsyncThunk('search/fetchSearchFoodProduct', async({id:id, sTerm:sTerm}) => {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/search/search-food-data/${id}/?sTerm=${sTerm}`)
    // console.log('first', response.data)
    return response.data
})

const searchSlice = createSlice({
  name:'search',
  initialState: initialState,
  
  extraReducers: (builder) => {
    builder.addCase(fetchSearchProduct.fulfilled, (state, action) => {
        state.products = action.payload
    })

    builder.addCase(fetchSearchFoodProduct.fulfilled, (state, action) => {
        state.foodProduct = action.payload
    })
  }
})

export const searchAction = searchSlice.actions
export const searchReducer = searchSlice.reducer