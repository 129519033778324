import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  userOrderDetails: [],
  orders: [],
  currentuserCreatedOrder: [],
  userPastOrders: [],
};

export const createUserOrder = createAsyncThunk(
  "order/createUserOrder",
  async (data) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/order/order-create`,
      data
    );
    return response.data;
  }
);

export const confirmOrder = createAsyncThunk(
  "order/confirmOrder",
  async (id) => {
    await axios.patch(
      `${process.env.REACT_APP_API_BASE_URL}/order/confirm-order/${id}`,
      {},
      {
        headers: {
          authorization: localStorage.getItem("token")
            ? `Bearer Bearer ${localStorage.getItem("token")}`
            : null,
        },
      }
    );
  }
);

export const fetchAllOrders = createAsyncThunk(
  "order/fetchAllOrder",
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/order/get-order`
    );
    return response.data;
  }
);

export const deleteOrder = createAsyncThunk("order/deleteOrder", async (id) => {
  await axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/order/delete-order/${id}`,
    {
      headers: {
        authorization: localStorage.getItem("token")
          ? `Bearer Bearer ${localStorage.getItem("token")}`
          : null,
      },
    }
  );
});
export const fetchUserOrder = createAsyncThunk(
  "order/fetchUserOrder",
  async (userId) => {
    if(userId){
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/order/get-user-orders`, {userId} 
      );
      return response.data;
    }
  }
);


const orderSlice = createSlice({
  name: "order",
  initialState: initialState,
  reducers: {
    currentOrder: (state, action) => {
      // console.log('payload>>>', action.payload)
      state.currentuserCreatedOrder = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(createUserOrder.fulfilled, (state, action) => {
      state.userOrderDetails = action.payload;
    });
    builder.addCase(fetchAllOrders.fulfilled, (state, action) => {
      state.orders = action.payload;
    });
    builder.addCase(fetchUserOrder.fulfilled, (state, action) => {
      state.userPastOrders = action.payload;
    });
  },
});

export const oredrAction = orderSlice.actions;
export const oredrReducer = orderSlice.reducer;
