import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import AdminHeader from "../../Adminpanel/AdminHeader";
import "./dashboardHome.css";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";

const Sidebar = () => {
  const navigate = useNavigate();

  const handleAdminSignout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };
  const adminData = localStorage.getItem("adminData")
    ? localStorage.getItem("adminData")
    : "";

  const { username } = JSON.parse(adminData);

  const [showMenu, setShowMenu] = useState(true)
  const handleClick = value => {
    setShowMenu(value);
  }
  return (
    <>
      <AdminHeader handleClick={handleClick} />
      {/* <button onClick={pre => setShowDisplay(!showDisplay)}>close</button> */}
      <div
        className={`l-navbar ${showMenu ? "_show" : "displayHide"} `}
        id="nav-bar"
      >
        <nav className="">
          <div>
            {" "}
            <div href="#" className="nav_logo">
              {" "}
              <i className="bx bx-layer nav_logo-icon"></i>{" "}
              <span className="nav_logo-name">Admin Panel</span>{" "}
            </div>
            <hr />
            <div className="nav_list">
              {" "}
              <Link to="/dashboard" className="nav_link">
                <span className="nav_name">Dashboard</span>{" "}
              </Link>{" "}
              {/* Restaurant */}
              <ul>
                <li>
                  <Link
                    to="#submenu2"
                    data-bs-toggle="collapse"
                    className="nav-link px-0 align-middle"
                  >
                    <span className="ms-1 d-sm-inline text-light">
                      Restaurant <ExpandMoreOutlinedIcon />
                    </span>
                  </Link>
                  <ul
                    className="collapse hide nav flex-column ms-1"
                    id="submenu2"
                    data-bs-parent="#menu"
                  >
                    <li className="w-100">
                      <NavLink
                        to="/dashboard/show-restaurants"
                        className="nav_link"
                      >
                        <span className="nav_name">Show Restaurants</span>{" "}
                      </NavLink>{" "}
                      <NavLink to="/dashboard/add-data" className="nav_link">
                        <span className="nav_name">Add Restaurants</span>{" "}
                      </NavLink>{" "}
                    </li>
                  </ul>
                </li>
              </ul>
              {/* Product */}
              <ul>
                <li>
                  <NavLink
                    to="#submenu1"
                    data-bs-toggle="collapse"
                    className="nav-link px-0 align-middle"
                  >
                    <i className="fa-regular fa-gauge"></i>{" "}
                    <span className="ms-1 d-sm-inline text-light">
                      Product <ExpandMoreOutlinedIcon />
                    </span>
                  </NavLink>
                  <ul
                    className="collapse hide nav flex-column ms-1"
                    id="submenu1"
                    data-bs-parent="#menu"
                  >
                    <li className="w-100">
                      <NavLink to="/dashboard/add-product" className="nav_link">
                        <span className="nav_name">Add Product</span>{" "}
                      </NavLink>{" "}
                    </li>
                  </ul>
                </li>
              </ul>
              {/* Orders */}
              <ul>
                <li>
                  <NavLink
                    to="#submenu3"
                    data-bs-toggle="collapse"
                    className="nav-link px-0 align-middle"
                  >
                    <i className="fa-regular fa-gauge"></i>{" "}
                    <span className="ms-1 d-sm-inline text-light">
                      Order <ExpandMoreOutlinedIcon />
                    </span>
                  </NavLink>
                  <ul
                    className="collapse hide nav flex-column ms-1"
                    id="submenu3"
                    data-bs-parent="#menu"
                  >
                    <li className="w-100">
                      <NavLink to="/dashboard/show-order" className="nav_link">
                        <span className="nav_name">Show Orders</span>{" "}
                      </NavLink>{" "}
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>{" "}
          <a href="#" className="nav_link">
            {" "}
            <i className="bx bx-log-out nav_icon"></i>{" "}
            <span onClick={handleAdminSignout} className="nav_name">
              SignOut
            </span>{" "}
          </a>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;
