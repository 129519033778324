import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllData } from "../../../Redux/Slices/ProductSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Button } from "@mui/material";
import { Link, useParams } from "react-router-dom";

const ShowProduct = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchAllData(id));
  }, []);

  const productData = useSelector((state) => state.product.products);
  // console.log('productData>>>>.', productData)
  const resderProductData = productData[0]?.map((item) => (
    <tbody className="admin_panel_tbody" key={item.id}>
      <tr>
        <td>{item.product_name.substring(0, 35)}</td>
        <td>{item.product_decription.substring(0, 39)}</td>
        <td className="show_data_button">
          <Button
            className="mx-2 my-2"
            startIcon={<EditOutlinedIcon />}
            variant="outlined"
          >
            <Link to={`/dashboard/update-product/${item.id}`}>EDIT</Link>
          </Button>

          <Link to={`/dashboard/delete-product/${item.id}`}>
            <Button
              className="mx-2 my-2"
              startIcon={<DeleteIcon />}
              variant="outlined"
            >
              DELETE
            </Button>
          </Link>
        </td>
      </tr>
    </tbody>
  ));
  return (
    <div className="showData">
      <h2 className="d-flex justify-content-center mb-4">Product List</h2>
      <table className="table table-bordered border-secondary">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Descripton</th>
            <th style={{ textAlign: "center" }} scope="col">
              Perform an Operation{" "}
            </th>
          </tr>
        </thead>
        {resderProductData}
      </table>
    </div>
  );
};

export default ShowProduct;
