import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchLanLng } from "../../Redux/Slices/Restaurant";
import "./restaurantlist.css";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import { fetchSearchProduct } from "../../Redux/Slices/search";

// import img1 from "../assets/placeholder_image.png"

const RestaurantList = ({ sTerm }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSearchProduct(sTerm));
  }, [dispatch, sTerm]);

  const handleDispatch = (id) => {
    dispatch(fetchLanLng(id));
  };

  const restaurantData = useSelector((state) => state.search.products);

  const renderProductData = restaurantData?.map((item) => (
    <div key={item.id} className="col-md-3 my-2">
      <div className="card">
        <div className="img-card-section">
            <img src={item.images} className="card-img-top" alt="foodimage" />
        </div>
        <div className="card-body">
          <h5 className="card-title">{item.restaurant_name}</h5>
          <p className="card-text">
            {item.restaurant_decription.substring(0, 80)}..
          </p>
          <div className="rating">
            <Stack spacing={1}>
              <Rating name="read-only" value={3} readOnly />
            </Stack>
          </div>
          <div className="order_now">
            <Link
              to={`/food/${item.id}`}
              onClick={() => handleDispatch(item.id)}
              className="btn btn-primary"
            >
              Order now
            </Link>
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <div className="row restaurantlist col-md-12">
      <div className="no_data">
        {restaurantData.length === 0 ? (
          <div className="no_data_title">No Restaurent Found !!</div>
        ) : (
          ""
        )}
      </div>
      <h2>Restaurants</h2>
      {renderProductData}{" "}
    </div>
  );
};

export default RestaurantList;


  /* <CCard className="card-body" style={{ height:'25rem' }}>
<CCardImage orientation="top" style={{ height: "20vh", width:''}} src={item.restaurant_image} />
<CCardBody>
  <CCardTitle>{item.restaurant_name}</CCardTitle>
  <CCardText>{item.restaurant_decription.substring(0, 80)}..</CCardText>
  <div className="rating">
    <Stack spacing={1}>
      <Rating name="rating" defaultValue={4} />
    </Stack>
  </div>
  <Link  to={`/food/${item.id}`} onClick={() => handleDispatch(item.id)}>
    <CButton className="order_now">Order now</CButton>
  </Link>
</CCardBody>
</CCard> */

