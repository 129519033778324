import React, { useEffect, useState } from "react";
import "./map.css";
import L from "leaflet";
import { createControlComponent } from "@react-leaflet/core";
import "leaflet-routing-machine";
import { userAction } from "../../Redux/Slices/User";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import LocationMarker from "./LocationMarker";
import { useDispatch, useSelector } from "react-redux";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import { CloseFullscreen } from "@mui/icons-material";
import { fetchRestaurant } from "../../Redux/Slices/Restaurant";
import axios from "axios";

const Map = () => {
  const { restaurant } = useSelector((state) => state.restaurant);
  console.log("restaurant>>>", restaurant);

  const dispatch = useDispatch();
  const ResPosition = [
    restaurant?.restaurant_latitude ? restaurant?.restaurant_latitude : "",
    restaurant?.restaurant_longitude ? restaurant?.restaurant_longitude : "",
  ];

  const { products } = useSelector((state) => state.cart);
  let { restaurantId: id } = products[0];

  useEffect(() => {
    dispatch(fetchRestaurant(id))
  }, []);

  // Routing Control ===>
  const [totalDistance, setTotalDistance] = useState("");
  const [totalTime, setTotalTime] = useState("");
  const [name, setName] = useState("");
  const [userPostion, setUserPostion] = useState([]);

  const getPosition = (data) => {
    setUserPostion(data);
  };
  function layerComponents() {
    const instance = L.Routing.control({
      waypoints: [L.latLng(ResPosition), L.latLng(userPostion)],
      lineOptions: {
        styles: [{ color: "#00B0FF", weight: 4 }],
      },
      show: false,
      autoRoute: true,
      addWaypoints: true,
      routeWhileDragging: true,
      draggableWaypoints: true,
      fitSelectedRoutes: true,
      showAlternatives: false,
    }).on("routesfound", function (e) {
      setTotalDistance(e.routes[0].summary.totalDistance);
      setTotalTime(e.routes[0].summary.totalTime);
      setName(e.routes[0].name);
    });
    return instance;
  }

  useEffect(() => {
    dispatch(
      userAction.userAddress({
        totalDistance: totalDistance,
        totalTime: totalTime,
        name: name,
      })
    );
  }, [name, dispatch, totalTime, totalDistance]);

  const HandleRoutingMachine = createControlComponent(layerComponents);

  return (
    <div className="map">
      <MapContainer center={ResPosition} zoom={13} scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={ResPosition}></Marker>
        <HandleRoutingMachine />
        <LocationMarker getPosition={getPosition} />
      </MapContainer>
      <br />
      <button className="btn btn-primary location_title">
        <RoomOutlinedIcon />
        Choose your current location! click on the map!
      </button>
    </div>
  );
};
export default Map;
