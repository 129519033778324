import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    isLoggedIn: false,
    resLatLng: []
}

const adminSlice = createSlice({
    name: 'admin',
    initialState: initialState,
     reducers: {
        getResLatLng : (state, action) => {
            state.resLatLng = action.payload
        },
        isAdminLoggedIn : (state) => {
            state.isLoggedIn = true
        }
     }, 
})

export const adminAction = adminSlice.actions 
export const adminReducer = adminSlice.reducer