import React from "react";
import "./cart.css";

import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { cartAction } from "../../Redux/Slices/Cart";

const EmptyCart = () => {
  const dispatch = useDispatch();

  const handleCloseCart = () => {
    dispatch(cartAction.showCart());
  };
  return (
    <div className="cart">
      <CloseIcon
      onClick={handleCloseCart}
        style={{
          float: "right",
          marginTop: "10px",
          marginRight: "5px",
          fontSize: "30px",
          color: "black",
          cursor:"pointer"
        }}
      />
      <div className="cart_restaurant_title">
        <h2>Cart</h2>
        <hr />
      </div>
      <div className="cart_food_items">
        <div className="cart_food_title"></div>
        <div className="cart_food_price">

          <h6 style={{marginTop:'150px', marginBottom:'150px', marginRight:'90px', color:'red'}}>No Cart Data is avilable</h6>
        </div>
      </div>
    </div>
  );
};

export default EmptyCart;
