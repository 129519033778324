import React, { useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loader from '../../Loader/Loader'
import { deleteProduct } from "../../../Redux/Slices/ProductSlice";

const DeleteProduct = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(deleteProduct(id))
    setTimeout(() => {
      navigate("/dashboard/show-products");
    }, 1000);
  }, [id]);

  return (
    <div>
      <Loader/>
    </div>
  );
};

export default DeleteProduct;
