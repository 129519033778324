import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Map from "../Map/Map";
import "./oredrSucecss.css";
import RouteIcon from "@mui/icons-material/Route";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import { useDispatch } from "react-redux";
import { cartAction, fetchSingleProduct, removeCartProduct } from "../../Redux/Slices/Cart";
import { oredrAction } from "../../Redux/Slices/Order";

import axios from "axios";

const OrderSuccess = () => {
  const dispatch = useDispatch();
  const cartProduct = useSelector((state) => state.cart.products);
  const userAddress = useSelector((state) => state.user.userAddress);
  const cartArrayProduct = cartProduct.map((item) => {
    return {
      productId: item.id,
      productName: item.product_name,
      images: item.images,
      productDescription: item.product_decription,
      quantity: item.quantity,
      totalPrice: item.totalPrice,
    };
  });
  // console.log("cartArrayProduct>>>", cartProduct);
  // console.log("cartArrayProduct>>>", cartArrayProduct);
  const CartIsEmapty = (
    <div className="empty_cart">
      <img
        style={{ width: "28rem" }}
        src="https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/2xempty_cart_yfxml0"
        alt=""
      />
      <h2>Your cart is empty</h2>
      <Link to="/">Go to Home</Link>
    </div>
  );

  var minutes = Math.floor(userAddress?.totalTime / 60);
  const distance = userAddress?.totalDistance / 1000;
  const d = parseFloat(distance).toFixed(2);

  let total = 0;
  cartProduct?.map((item) => {
    total = total + item.totalPrice;
    return total;
  });

  const { userId } = JSON.parse(localStorage?.getItem("userdata"));


  const [addressData, setAddressData] = useState({});

  // console.log("addressData.length>>>>", addressData[0]?.id);
  useEffect(() => {
    const userCurrentAddress = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/address/get-address/${userId}`
      );
      // console.log("response data>>>", response.data);
      setAddressData(response.data);
    };
    userCurrentAddress();
  }, []);

  // const address = useSelector((state) => state.user.userCurrentAddress);

  // cart functionality-- 
  const handleAddtoCart = (item) => {
    dispatch(fetchSingleProduct(item))
  }

  const handleRemoveCart = (id) => {
    dispatch(removeCartProduct(id))
  }

  // create order 
  const data = {
    productId: 896,
    totalItem: cartProduct.length,
    totalAmount: total,
    userId: userId,
    addressId: addressData[0]?.id,
    orderStatus: false,
    productIdArray: cartArrayProduct,
  };

const createOrder = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/order/order-create`,
      data
    );

    dispatch(oredrAction.currentOrder(response.data));
    dispatch(cartAction.cartEmptyAfterOdering());
  };


  // render add to cart product..
  const product = cartProduct?.map((item) => (
    <tr
      key={item.id}
      className="product_details"
      style={{ textAlign: "center" }}
    >
      <th
        style={{ fontSize: "16px", fontWeight: "400", textAlign: "center" }}
        className="food_name"
      >
        {item?.product_name}
      </th>
      <th
        style={{ fontSize: "16px", fontWeight: "400", textAlign: "center" }}
        className="quantity"
      >
        <th className="food_name" style={{cursor:"pointer"}} onClick={() => handleRemoveCart(item.id)}>-</th>
        {item?.quantity}
        <th className="food_name" style={{cursor:"pointer"}} onClick={() => handleAddtoCart(item)}>+</th>
      </th>
      <th
        style={{ fontSize: "16px", fontWeight: "400", textAlign: "center" }}
        className="price"
      >
        ₹ {item?.product_price}
      </th>
      <th
        style={{ fontSize: "16px", fontWeight: "400", textAlign: "center" }}
        className="price"
      >
        ₹ {item?.totalPrice}
      </th>
    </tr>
  ));



  return (
    <>
      {cartProduct.length === 0 ? (
        <>{CartIsEmapty}</>
      ) : (
        <div className="order_success">
          <div className="map__">
            <Map />
          </div>
          <div className="adress_details">
            <div className="adress_notes">
              <div className="your_adresss">
                <div>Address Details</div>
              </div>
              <div className="adress_title">
                <RoomOutlinedIcon style={{ fontSize: "32px" }} />
                {userAddress.name ? (
                  userAddress.name
                ) : (
                  <p>Select your location</p>
                )}
              </div>
              <div className="adress_distance">
                <RouteIcon /> Distance: <span>{d} Km</span>
              </div>
              <div className="adrress_time">
                <AccessTimeIcon /> Time : <span>{minutes}</span> min
              </div>
            </div>
            <div className="paynow">
              <table style={{ textAlign: "center" }} className="product table">
                <tr className="product_details_title">
                  <th className="food_name">Name</th>
                  <th className="quantity">Quantity</th>
                  <th className="price">Item Price</th>
                  <th className="price">Total Price</th>
                </tr>
                {product}
              </table>
              <div className="delivery_charges">
                <span>Delivery charges &nbsp; &nbsp; ₹20</span>
              </div>
              <div className="order_confirm">
                <div style={{ width: "22rem" }}>
                  <Link
                    onClick={createOrder}
                    to={
                      addressData.length === 0
                        ? "/add-address"
                        : "/order-details"
                    }
                    className="btn btn-success"
                  >
                    Confirm Your Order
                  </Link>
                </div>
                <div>
                  <span className="total">₹ {total + 20}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderSuccess;
