import React, { useEffect } from "react";
import "./restaurantfood.css";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import FoodList from "./FoodList";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllData } from "../../Redux/Slices/ProductSlice";
import { fetchRestaurant } from "../../Redux/Slices/Restaurant";
import img1 from "../assets/placeholder_image.png";
import StarIcon from "@mui/icons-material/Star";
const RestaurantFood = ({ sTerm }) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllData(id));
    dispatch(fetchRestaurant(id));
  }, [dispatch, id]);

  const restaurant = useSelector((state) => state.restaurant.restaurant);
  const productData = useSelector((state) => state.product.products);
  return (
    <>
      <div className="restaurantfood">
        <div className="row show-grid p-3">
          <div className="col-md-4">
            {restaurant?.images === null ? (
              <img
                src={img1}
                className="img-thumbnail"
                style={{ width: "40vw" }}
                alt=".."
              />
            ) : (
              <img src={restaurant?.images} alt="" style={{ width: "18vw" }} />
            )}
          </div>

          <div className="col-md-4">
            <div className="restaurantfood_details">
              <h2 className="restaurantfood_name">
                {restaurant?.restaurant_name}
              </h2>
              <span className="restaurantfood_type">
                {restaurant?.restaurant_type}
              </span>
              <p className="restaurantfood_description">
                {restaurant?.restaurant_decription}
              </p>
              <div className="row restaurantfood_rating ">
                <div className="col-md-4 text-light">
                  <div className="_2iUp9 ">
                    <div className="_2l3H5">
                      <span>
                        <StarIcon /> 4.3
                      </span>
                    </div>
                    <div className="_1De48">
                      <span className="_1iYuU">10K+ ratings</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 text-light">
                  <div className="_2iUp9 ">
                    <div className="_2l3H5">
                      <span>
                        <span className="icon-star _2n5YQ"></span>16.5 min
                      </span>
                    </div>
                    <div className="_1De48">
                      <span className="_1iYuU">Minimum time</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 text-light">
                  <div className="_2iUp9 ">
                    <div className="_2l3H5">
                      <span>
                        <span className="icon-star _2n5YQ"></span>59 ₹
                      </span>
                    </div>
                    <div className="_1De48">
                      <span className="_1iYuU">Minimum price</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="_2FyFZ">
              <div className="QWCzK">Offer</div>
              <div className="_3F2Nk">
                <div className="DM5zR">
                  <div className="_3lvLZ d-flex">
                    <div>
                     <LocalOfferIcon />
                    </div>&nbsp; &nbsp;&nbsp; 
                    <div>20% off up to ₹120 | Use FEDERALCC Above ₹249</div>
                  </div>
                  <div className="_3lvLZ d-flex">
                    <div>
                     <LocalOfferIcon />
                    </div>&nbsp;&nbsp;&nbsp;
                    <div>60% off up to ₹100 | Use code WELCOMEBACK</div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FoodList id={id} productData={productData} sTerm={sTerm} />
      </div>
    </>
  );
};

export default RestaurantFood;
