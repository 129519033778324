import React, { useEffect } from "react";
import "./userProfile.css";
import { useDispatch, useSelector } from "react-redux";
import { getUserAddress } from "../../Redux/Slices/User";
import { Link } from "react-router-dom";


const UserProfile = () => {
  const dispatch = useDispatch();
  const address = useSelector((state) => state.user.userCurrentAddress)[0];
  // console.log("address>>>", address);
  const { userDetails } = useSelector((state) => state.user);
  const { userId, username, email } = userDetails;

  useEffect(() => {
    dispatch(getUserAddress(userId));
  }, [dispatch, userId]);
  const { isAuthUser } = useSelector((state) => state.user);
  return (
    <>
      {isAuthUser ? (
        <div className="container emp-profile">
          <form method="post">
            <div className="row">
              <div className="col-md-4">
                <div className="profile-img">
                  {/* <img
                    src="https://i.pinimg.com/originals/3f/94/70/3f9470b34a8e3f526dbdb022f9f19cf7.jpg"
                    alt=""
                    style={{width:'140px'}}
                  /> */}
                </div>
              </div>
              <div className="col-md-6 d-flex">
                <div className="">
                  <h3>Profile Details</h3>
                </div>
              </div>
              <hr />
            </div>
            <div className="row mx-5">
              <div className="col-md-8 __profile mx-5 ">
                <div className="tab-content profile-tab" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="row mx-5">
                      <div className="col-md-6">
                        <label>Name</label>
                      </div>
                      <div className="col-md-6">
                        <p>{username}</p>
                      </div>
                    </div>
                    <div className="row mx-5">
                      <div className="col-md-6">
                        <label>Email</label>
                      </div>
                      <div className="col-md-6">
                        <p>{email}</p>
                      </div>
                    </div>
                    <div className="row mx-5">
                      <div className="col-md-6">
                        <label>Phone</label>
                      </div>
                      <div className="col-md-6">
                        <p>{address?.phone ? address?.phone : "-"}</p>
                      </div>
                    </div>
                    <div className="row mx-5">
                      <div className="col-md-6">
                        <label>PinCode</label>
                      </div>
                      <div className="col-md-6">
                        <p>
                          {address?.areaPincode ? address?.areaPincode : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row mx-5">
                      <div className="col-md-6">
                        <label>Address</label>
                      </div>
                      <div className="col-md-6">
                        <p>
                          {address?.currentAddress
                            ? address?.currentAddress
                            : "-"}
                        </p>
                      </div>
                      <Link
                        to={
                          address?.id === undefined
                            ? `/add-address`
                            : `/update-user-address/${userId}`
                        }
                        style={{
                          fontSize: "12px",
                          textDecoration: "underline",
                          marginBottom: "50px",
                          color: "blue",
                        }}
                      >
                        Edit Address
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <h2 style={{ marginTop: "150px" }}> You are not logged in</h2>
      )}
    </>
  );
};

export default UserProfile;
