import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
 import './loader.css'

const Loader = () => {
  return (
    <div className="loader">
      <CircularProgress /><br />
    <span>Loading...</span>
    <span>Please wait</span>

    </div>
  );
};

export default Loader;
