import React, { useState } from "react";
import './searchbar.css'
const Searchbar = ({setSTerm, placeHolder}) => {
    const [term, setTerm] = useState('')
    const handleOnChange = (e) => {
           setTerm(e.target.value) 
           setSTerm(e.target.value)
    }
  return (  
    <form className="d-flex searchbar">
      <input 
        onChange={handleOnChange}
        className="form-control my-4"
        type="search"
        placeholder={placeHolder}
        aria-label="Search"
      />
    </form>
  );
};

export default Searchbar;
