import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  confirmOrder,
  deleteOrder,
  fetchAllOrders,
} from "../../Redux/Slices/Order";
import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const ShowOrder = () => {
  const dispatch = useDispatch();
  const orderData = useSelector((state) => state.order.orders);

  const [changeData, setChangeData] = useState(false);
  useEffect(() => {
    dispatch(fetchAllOrders());
  }, [changeData]);

  const handleConfirmOrder = (id) => {
    dispatch(confirmOrder(id));
    setChangeData(!changeData);
  };

  const handleDeleteOrder = (id) => {
    dispatch(deleteOrder(id));
    setChangeData(!changeData);
  };

  const renderOrderList = orderData?.map((item) => (
    <tbody className="admin_panel_tbody" key={item.id}>
      <tr>
        <td>{item.id}</td>
        <td>{item.product.product_name}</td>
        <td>{item.totalItem}</td>
        <td>{item.totalAmount}</td>
        <td>{item.address.currentAddress}</td>
        <td>{item.user.username}</td>
        {item.orderStatus ? <td>Confirm</td> : <td>Pennding</td>}
        <td className="show_data_button">
          <Button
            className="mx-2 my-2"
            startIcon={<EditOutlinedIcon />}
            variant="contained"
            color="success"
            onClick={() => handleConfirmOrder(item.id)}
          >
            Confirm Order
            {/* <Link to={`/dashboard/update-product/${item.id}`}>Delete Order</Link> */}
          </Button>

          {/* <Link to={`/dashboard/delete-product/${item.id}`}> */}
          <Button
            className="mx-2 my-2"
            startIcon={<DeleteIcon />}
            variant="contained"
            onClick={() => handleDeleteOrder(item.id)}
          >
            DELETE
          </Button>
          {/* </Link> */}
        </td>
      </tr>
    </tbody>
  ));

  //   console.log("Order>>>>", orderData);
  return (
    <div className="showData">
      <h2 className="d-flex justify-content-center mb-4">Order List</h2>
      <table className="table table-bordered border-secondary">
        <thead>
          <tr>
            <th scope="col">Order Id</th>
            <th scope="col">Product Name</th>
            <th scope="col">Total Item</th>
            <th scope="col">Total Price</th>
            <th scope="col">Address</th>
            <th scope="col">Username</th>
            <th scope="col">Order Status</th>

            <th style={{ textAlign: "center" }} scope="col">
              Perform an Operation{" "}
            </th>
          </tr>
        </thead>
        {renderOrderList}
      </table>
    </div>
  );
};

export default ShowOrder;
