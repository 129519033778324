import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const initialState = {
  restaurants: [],
  restaurant: [],
};

export const fetchAllData = createAsyncThunk(
  "restaurant/fetchAllRestaurant",
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/restaurant/get-restaurants`
    );
    return response.data;
  }
);
export const fetchLanLng = createAsyncThunk(
  "product/fetchLatLng",
  async (data) => {
    return data;
  }
);
export const fetchRestaurant = createAsyncThunk(
  "restaurant/fetchRestaurant",
  async (id) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/restaurant/get-restaurant/${id}`
    );
    console.log('resdpnse data from resdux>>', response.data)
    return response.data;
  }
);

export const addRestaurantData = createAsyncThunk(
  "restaurant/addRestaruntData",
  async (data) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/restaurant/add-restaurants`,
      data,
      {
        headers: {
          authorization: localStorage.getItem("token")
            ? `Bearer Bearer ${localStorage.getItem("token")}`
            : null,
        },
      }
    );
    // console.log("Add Data Response >>", response.data);
    return response.data;
  }
);
export const updateRestaurantData = createAsyncThunk(
  "restaurant/updateRestaruntData",
  async ({ id, formData }) => {
    await axios.patch(
      `${process.env.REACT_APP_API_BASE_URL}/restaurant/update-restaurant/${id}`,
      formData,
      {
        headers: {
          authorization: localStorage.getItem("token")
          ? `Bearer Bearer ${localStorage.getItem("token")}`
          : null,
        }
      }
    );
  }
);

export const deleteRestarunt = createAsyncThunk(
  "restaurant/deleteData",
  async (id) => {
    axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/restaurant/delete-restaurant/${id}`,
      {
        headers: {
          authorization: localStorage.getItem("token")
          ? `Bearer Bearer ${localStorage.getItem("token")}`
          : null,
        }
      }
    );
  }
);

const restaurantSlice = createSlice({
  name: "restaurant",
  initialState: initialState,

  extraReducers: (builder) => {
    builder.addCase(fetchAllData.fulfilled, (state, action) => {
      state.restaurants = action.payload;
    });
    builder
      .addCase(fetchRestaurant.fulfilled, (state, action) => {
        state.restaurant = action.payload;
      })
  },
});

export const restaurantAction = restaurantSlice.actions;
export const restaurantReducer = restaurantSlice.reducer;
