import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./showdata.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllData,
} from "../../../Redux/Slices/Restaurant";
import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Link } from "react-router-dom";

const ShowData = () => {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllData());
  }, []);

  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/restaurant/get-page-restaurant?page=${pageNumber}`)
      .then((response) => response.json())
      .then(({ totalPages, posts }) => {
        setPosts(posts);
        setNumberOfPages(totalPages);
      });
  }, [pageNumber]);
  const handlePageClick = async (data) => {
    setPageNumber(data.selected)
  }; 
  
  
  
  
  
  const restaurantData = useSelector((state) => state.restaurant.restaurants);
  const renderRestaurantData = restaurantData?.map((item) => (
    <tbody className="admin_panel_tbody" key={item.id}>
      <tr>
        <td>{item.restaurant_name.substring(0, 35)}</td>
        <td>{item.restaurant_type.substring(0, 39)}</td>
        <td className="show_data_button">
          <Button
            className="mx-2 my-2"
            startIcon={<EditOutlinedIcon />}
            variant="outlined"
          >
            <Link to={`/dashboard/update/${item.id}`}>EDIT</Link>
          </Button>
          <Link to={`/dashboard/deletedata/${item.id}`}>
            <Button
              className="mx-2 my-2"
              startIcon={<DeleteIcon />}
              variant="outlined"
            >
              DELETE
            </Button>
          </Link>
          <Link to={`/dashboard/show-products/${item.id}`}>
            <Button
              className="mx-2 my-2"
              startIcon={<DeleteIcon />}
              variant="outlined"
            >
              View Product
            </Button>
          </Link>
        </td>
      </tr>
    </tbody>
  ));

  return (
    <div className="showData">
      <h2 className="d-flex justify-content-center mb-4">Restaurant List</h2>
      <table className="table table-bordered border-secondary">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Type</th>
            <th style={{ textAlign: "center" }} scope="col">
              Perform an Operation{" "}
            </th>
          </tr>
        </thead>
        {renderRestaurantData}
      </table>
    </div>
  );
};

export default ShowData;
