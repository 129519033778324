import "./App.css";
import AdminPanel from "./components/AdminPanel";
import Home from "./components/Home";
import Navbar from "./components/Navbar/Navbar";

function App() {
  return (
    <div className="App">
      <div className="home">
        <Navbar />
        <Home /> 
      </div>
      <AdminPanel />
    </div>
  );
}
export default App;
// @coreui/coreui @coreui/icons @coreui/icons-react @coreui/react bootstrap
 