import React from "react";
import "./navbar.css";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Link, useNavigate } from "react-router-dom";
import img1 from "../assets/swiggy.png";
import { useDispatch, useSelector } from "react-redux";
import { cartAction } from "../../Redux/Slices/Cart";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { userAction } from "../../Redux/Slices/User";
import { toast } from "react-toastify";

const Navbar = ({ navbar }) => {
  const cartProduct = useSelector((state) => state.cart.products);
  const dispatch = useDispatch();
  const { isAuthUser } = useSelector((state) => state.user);

  let total = 0;
  if (cartProduct.length > 0) {
    cartProduct?.map((item) => {
      total = total + item.quantity;
      return total;
    });
  }

  const handleShowCart = () => {
    dispatch(cartAction.showCart());
  };
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("userdata");
    dispatch(userAction.userLogout());
    dispatch(cartAction.cartEmptyAfterOdering());

    toast("You are Loggedout!!");
    navigate("/");
  };

  // const userData = JSON.parse(localStorage.getItem("userdata"));
  return (
    <>
      <nav
        style={{ display: `${navbar}` }}
        className="navbar navbar-expand-lg navbar-light bg-light"
      >
        <div className="container-fluid">
          <img className="navbar_iimg" src={img1} alt="" />
          <Link className="navbar-brand" to="/">
            SWIGGY
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/">
                  Home
                </Link>
              </li>

              {!isAuthUser ? (
                <>
                  <li className="nav-item">
                    <Link
                      className="nav-link active"
                      aria-current="page"
                      to="/user/login"
                    >
                      Signin
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link active"
                      aria-current="page"
                      to="/user/registration"
                    >
                      Signup
                    </Link>
                  </li>
                </>
              ) : null}
            </ul>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            ></div>
          </div>
          {isAuthUser ? (
            <Link to="/user-profile" className="navbar_cart_quantity">
              <AccountBoxIcon
                style={{
                  color: "black",
                  marginLeft: "20px",
                  fontSize: "30px",
                  cursor: "pointer",
                }}
              />
            </Link>
          ) : null}
          <div
            className="navbar_cart_quantity nav-item"
            onClick={handleShowCart}
          >
            <div className="navbar_cart_quantity_number">{total}</div>
            <ShoppingCartIcon
              style={{
                color: "black",
                marginLeft: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        {isAuthUser ? (
          <button
            className="btn btn-primary mx-4 nav-item"
            onClick={handleLogout}
          >
            Logout
          </button>
        ) : null}
      </nav>
      {/* {showCart === true ? <Cart /> : ""} */}
    </>
  );
};

export default Navbar;
