import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addUserCurrentAddress,
  getUserAddress,
} from "../../Redux/Slices/User";
import "./addAddress.css";

const AddUserAddress = () => {
  const [currentAddress, setCurrentAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [areaPincode, setAreaPincode] = useState("");
  const { userDetails } = useSelector((state) => state.user);
  let { userId } = userDetails
    ? userDetails
    : JSON.parse(localStorage.getItem("userdata"));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = {
    currentAddress,
    phone,
    areaPincode,
    userId,
  };

  const handleAddAddress = (e) => {
    e.preventDefault();
    dispatch(addUserCurrentAddress(data));
    // dispatch(userAction.isSaveAddress());
  };
  const { saveAddress } = useSelector((state) => state.user);
  if (saveAddress) {
    setCurrentAddress("");
    setAreaPincode("");
    setPhone("");
    navigate("/create-address");
  }

  useEffect(() => {
    dispatch(getUserAddress(userId));
  }, [dispatch, userId]);

  return (
    <form
      onSubmit={handleAddAddress}
      className="col g-3 col-md-12 user_address"
    >
      <h2 className="user_address_heading">Type Your Delivery Address</h2>
      <hr style={{ width: "35vw" }} />

      <div className="col-6">
        <label htmlFor="inputAddress" className="form-label">
          Full Address
        </label>
        <input
          required
          value={currentAddress}
          type="text"
          className="form-control"
          onChange={(e) => setCurrentAddress((pre) => e.target.value)}
          id="inputAddress"
          autoComplete="off"
        />
      </div>
      <div className="col-6">
        <label htmlFor="inputPhone" className="form-label">
          Phone Number
        </label>
        <input
          required
          value={phone}
          type="number"
          onChange={(e) => setPhone((pre) => e.target.value)}
          className="form-control"
          id="inputPhone"
          autoComplete="off"
        />
      </div>
      <div className="col-md-6">
        <label htmlFor="inputZip" className="form-label">
          Zip code
        </label>
        <input
          required
          value={areaPincode}
          type="number"
          className="form-control"
          onChange={(e) => setAreaPincode((pre) => e.target.value)}
          id="inputZip"
          autoComplete="off"
        />
      </div>
      <div className="col-12 my-4">
        <button type="submit" className="btn btn-primary">
          Add Address
        </button>
      </div>
    </form>
  );
};

export default AddUserAddress;
