import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
const initialState = {
  products: [],
  cartProduct: [],
  allProducts: [],
};

export const fetchAllData = createAsyncThunk(
  "product/fetchAllData",
  async (restaurantId) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/product/get-product/${restaurantId}`
    );
    return response.data;
  }
);

export const fetchSingleProduct = createAsyncThunk(
  "product/fetchSingleData",
  async (id) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/product/get-product/${id}`
    );
    return response.data;
  }
);

export const addProductData = createAsyncThunk(
  "product/addProductData",
  async (formData) => {
    await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/product/add-product`,
      formData,
      {
       headers: {
        authorization: localStorage.getItem("token")
        ? `Bearer Bearer ${localStorage.getItem("token")}`
        : null,
       }
      }
    );
  }
);

export const getAllProduct = createAsyncThunk(
  "product/getAllProduct",
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/product/get-all-product`
    );
    return response.data;
  }
);

export const updateProduct = createAsyncThunk(
  "product/updateProduct",
  async ({ id, formData }) => {
    const response = await axios.patch(
      `${process.env.REACT_APP_API_BASE_URL}/product/update-product/${id}`,
      formData,
      {
        headers: {
          authorization: localStorage.getItem("token")
            ? `Bearer Bearer ${localStorage.getItem("token")}`
            : null,
        },
      }
    );
    return response.data;
  }
);

export const deleteProduct = createAsyncThunk(
  "product/deleteProduct",
  async (id) => {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/product/delete-product/${id}`,
      {
        headers: {
          authorization: localStorage.getItem("token")
            ? `Bearer Bearer ${localStorage.getItem("token")}`
            : null,
        },
      }
    );
    console.log("");
    toast(response.data.message);
  }
);

const productSlice = createSlice({
  name: "product",
  initialState: initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchAllData.pending, (state, action) => {
        state.products = [];
      })
      .addCase(fetchAllData.fulfilled, (state, action) => {
        state.products.push(action.payload);
      });
    builder.addCase(getAllProduct.fulfilled, (state, action) => {
      state.allProducts = action.payload;
    });
  },
});

export const productAction = productSlice.actions;
export const productReducer = productSlice.reducer;
