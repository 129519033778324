import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { updateUserAddress } from "../../Redux/Slices/User";

const EditAddress = () => {
  const [currentAddress, setCurrentAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [areaPincode, setAreaPincode] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/address/get-address/${id}`
      );
      // console.log("update address>>", response.data);
      if (response) {
        setCurrentAddress(response.data[0]?.currentAddress);
        setPhone(response.data[0]?.phone);
        setAreaPincode(response.data[0]?.areaPincode);
      }
    };
    getData();
  }, [id]);
  const data = {
    currentAddress,
    phone,
    areaPincode,
    id,
  };
  const { updateAddress } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const handleUpdateAddress = (e) => {
    e.preventDefault();
    dispatch(updateUserAddress({ id: id, data: data }));
  };
  if (updateAddress) {
    navigate("/user-profile");
  }
  return (
    <form
      onSubmit={handleUpdateAddress}
      className="col g-3 col-md-12 user_address"
    >
      <h2 className="user_address_heading">Edit Your Delivery Address</h2>
      <hr style={{ width: "35vw" }} />

      <div className="col-6">
        <label htmlFor="inputAddress" className="form-label">
          Full Address
        </label>
        <input
          value={currentAddress}
          type="text"
          className="form-control"
          onChange={(e) => setCurrentAddress((pre) => e.target.value)}
          id="inputAddress"
          autoComplete="off"
        />
      </div>
      <div className="col-6">
        <label htmlFor="inputPhone" className="form-label">
          Phone Number
        </label>
        <input
          value={phone}
          type="text"
          onChange={(e) => setPhone((pre) => e.target.value)}
          className="form-control"
          id="inputPhone"
          autoComplete="off"
        />
      </div>
      <div className="col-md-6">
        <label htmlFor="inputZip" className="form-label">
          Zip code
        </label>
        <input
          value={areaPincode}
          type="text"
          className="form-control"
          onChange={(e) => setAreaPincode((pre) => e.target.value)}
          id="inputZip"
          autoComplete="off"
        />
      </div>
      <div className="col-12 my-4">
        <button type="submit" className="btn btn-primary">
          Add Address
        </button>
      </div>
    </form>
  );
};

export default EditAddress;
