import React from 'react'
import ShowData from "./Dashboard/Showdata/ShowData";
import AddRestaurant from "./Dashboard/ShowRestaurant/AddRestaurant";
import Sidebar from "./Dashboard/Sidebar/Sidebar";
import UpdateData from "./Dashboard/UpdateData/UpdateData";
import ShowProduct from "./Dashboard/ShowProduct/ShowProduct";
import DeleteData from "./Dashboard/Showdata/DeleteData";
import Dashboard from "./Dashboard/DashboardHome/Dashboard";
import UpdateProduct from "./Dashboard/UpdateProduct/UpdateProduct";
import DeleteProduct from "./Dashboard/ShowProduct/DeleteProduct";
import ShowOrder from "./Order/ShowOrder";
import { Route, Routes, Navigate} from 'react-router-dom';
import { useSelector } from 'react-redux';
import AddMoreProduct from './Dashboard/AddMoreProduct/AddMoreProduct';

const AdminPanel = () => {
    const isAdminLoggedIn = useSelector((state) => state.admin.isLoggedIn);
  return (
    <div style={{display:"flex"}}> 
    <Routes>
    <Route
        path="/dashboard"
        element={
          isAdminLoggedIn || localStorage.getItem("token") ? (
            <>
              <Sidebar />
              <Dashboard />
            </>
          ) : (
            <Navigate to="/admin/login" />
          )
        }
      />
      <Route
        path="/dashboard/show-restaurants"
        element={
          <>
            <Sidebar />
            <ShowData />
          </>
        }
      />
      <Route
        path="/dashboard/add-data"
        element={
          <>
            <Sidebar />
            <AddRestaurant />
          </>
        }
      />
      <Route
        path="/dashboard/add-product"
        element={
          <>
            <Sidebar />
            <AddMoreProduct />
          </>
        }
      />
      <Route
        path="/dashboard/update/:id"
        element={
          <>
            <Sidebar />
            <UpdateData />
          </>
        }
      />

      <Route
        path="/dashboard/update-product/:id"
        element={
          <>
            <Sidebar />
            <UpdateProduct />
          </>
        }
      />

      <Route
        path="/dashboard/show-products/:id"
        element={
          <>
            <Sidebar />
            <ShowProduct />
          </>
        }
      />
      <Route
        path="/dashboard/deletedata/:id"
        element={
          <>
            <Sidebar />
            <DeleteData />
          </>
        }
      />
      <Route
        path="/dashboard/delete-product/:id"
        element={
          <>
            <Sidebar />
            <DeleteProduct />
          </>
        }
      />

      <Route
        path="/dashboard/show-order"
        element={
          <>
            <Sidebar />
            <ShowOrder />
          </>
        }
      />
    </Routes>
  </div>

  )
}

export default AdminPanel