import React, { useEffect, useState } from "react";
import "./foodlist.css";
import image1 from "../assets/pure_veg.png";
import StarIcon from "@mui/icons-material/Star";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleProduct } from "../../Redux/Slices/Cart";
import { fetchSearchFoodProduct } from "../../Redux/Slices/search";
import img1 from "../assets/placeholder_image.png";
const FoodList = ({ sTerm, id }) => {
  const [data, setData] = useState({});
  const dispatch = useDispatch();

  const serachProduct = useSelector((state) => state.search.foodProduct);

  useEffect(() => {
    dispatch(fetchSearchFoodProduct({ id: id, sTerm: sTerm }));
  }, [sTerm, id]);

  // Add to cart
  const handleAddToCart = (item) => {
    setData(item);
    dispatch(fetchSingleProduct(item));
  };
  const searchData = serachProduct?.filter((item) =>
    item.product_name.toLowerCase().includes(sTerm.toLowerCase())
  );

  return (
    <>
      <div className="no_product">
        {serachProduct.length === 0 ? (
          <div className="no_product_title">No product found !! </div>
        ) : (
          ""
        )}
      </div>
      {searchData?.map((item) => (
        <React.Fragment key={item.id}>
          <div className="main-product-list">
          <div className="product-img">
          <div className="foodList_image_bg"></div>
              <div className="foodlist_image">
                {item.images === null ? (
                  <img
                    src={img1}
                    className="img-thumbnail placeholder"
                    alt=".."
                  />
                ) : (
                  <img
                    src={item.images}
                    alt=""
                  />
                )}
              </div>
            </div>
            <div className="product-decrption">
              <div className="foodlist_content">
                <div className="foodlist_bestseller">
                  <img src={image1} alt="" style={{ width: "15px" }} />
                  <StarIcon style={{ color: "#EEA00F" }} />
                  <span style={{ color: "#EEA00F" }}> Bestseller </span>
                </div>
                <div>
                  <div className="foodlist_foodtitle">{item.product_name}</div>

                  <span className="foodlist_price">₹ {item.product_price}</span>
                  <p className="foodlist_description">
                    {item.product_decription}
                  </p>

                  <button
                    onClick={() => handleAddToCart(item)}
                    className="btn foodlist_addcart_btn"
                  >
                    Add to cart
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="main-product-list">
            <div className="product-img">
              <div className="foodlist_image">
                <div className="foodList_image_bg"></div>
                <img
                  src="http://localhost:5000/uploads/S_C_2.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="product-dec">
              <div className="foodlist_content">
                <div className="foodlist_bestseller">
                  <img
                    src="/static/media/pure_veg.fc7d733bb80f0183ab72.png"
                    alt=""
                  />
                  <span style="color: rgb(238, 160, 15);"> Bestseller </span>
                </div>
                <div>
                  <div className="foodlist_foodtitle">Chicken Whopper</div>
                  <span className="foodlist_price">₹ 129</span>
                  <p className="foodlist_description">
                    Our signature Whopper with 7 layers between the buns. Flame
                    Grilled chicken Patty, fresh onion, crispy lettuce, juicy.
                  </p>
                  <button className="btn foodlist_addcart_btn">Add to cart</button>
                </div>
              </div>
            </div>
          </div> */}

          <hr style={{ width: "100%" }} />
        </React.Fragment>
      ))}
    </>
  );
};

export default FoodList;
