import React, { useEffect } from "react";
import "./dashboard.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllData } from "../../../Redux/Slices/Restaurant";
import { Link } from "react-router-dom";
import { getAllProduct } from "../../../Redux/Slices/ProductSlice";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import RestaurantMenuOutlinedIcon from "@mui/icons-material/RestaurantMenuOutlined";
import FastfoodOutlinedIcon from "@mui/icons-material/FastfoodOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import { fetchAllOrders } from "../../../Redux/Slices/Order";
const Dashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllData());
    dispatch(getAllProduct());
    dispatch(fetchAllOrders());
  }, [dispatch]);

  const restaurantList = useSelector((state) => state.restaurant.restaurants);
  const productList = useSelector((state) => state.product.allProducts);
  const orderList = useSelector((state) => state.order.orders);

  return (
    <div className="dashboard__">
      <main className="container ">
        <div className="col-lg-4 ps"></div>
        <div className="col-lg-8">      
        </div>
        <div className="container-x1 px-5">
          <div className="col-mb-2 dashboard_title">
            <div className="col-sm-6">
              <h2 className="m-0">Dashboard</h2>
            </div>
          </div>
          <div className="row dashboard_container">
            <div className="col-lg-4 col-6">
              <div className="small-box bg-info">
                <div className="inner">
                  <div className="float-end">
                    <RestaurantMenuOutlinedIcon
                      className="res_icon"
                      style={{ fontSize: "70px" }}
                    />
                  </div>
                  <h3>{restaurantList.length}</h3>
                  <p>Total Restaurants</p>
                </div>
                <Link
                  to="/dashboard/show-restaurants"
                  className="small-box-footer"
                >
                  More info <ArrowCircleRightOutlinedIcon />
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-6">
              <div className="small-box bg-info">
                <div className="inner">
                  <div className="float-end">
                    <FastfoodOutlinedIcon
                      className="res_icon"
                      style={{ fontSize: "70px" }}
                    />
                  </div>
                  <h3>{productList.length}</h3>
                  <p>Total Product</p>
                </div>
                <div className="small-box-footer">
                  More info <ArrowCircleRightOutlinedIcon />
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-6">
              <div className="small-box bg-info">
                <div className="inner">
                  <div className="float-end">
                    <LocalMallOutlinedIcon
                      className="res_icon"
                      style={{ fontSize: "70px" }}
                    />
                  </div>
                  <h3>{orderList.length}</h3>
                  <p>New Orders</p>
                </div>
                <Link to="/dashboard/show-order" className="small-box-footer">
                  More info <ArrowCircleRightOutlinedIcon />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
