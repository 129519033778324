import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { addProductData } from "../../../Redux/Slices/ProductSlice";
import { fetchAllData } from "../../../Redux/Slices/Restaurant";
import "./addmoreproduct.css";

const AddMoreProduct = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllData());
  }, []);

  const Restaurant = useSelector((state) => state.restaurant.restaurants);
  const renderSelect = Restaurant?.map((item) => (
    <option key={item.id} value={item.id}>
      {item.restaurant_name}
    </option>
  ));

  const [product_name, setProduct_name] = useState("");
  const [product_price, setProduct_price] = useState("");
  const [product_description, setProduct_description] = useState("");
  const [restauramtId, setRestauramtId] = useState("");
  const [images, setImages] = useState("");

  const navigate = useNavigate();
  const formData = new FormData();

  formData.append("product_name", product_name);
  formData.append("product_price", product_price);
  formData.append("product_decription", product_description);
  formData.append("restaurantId", restauramtId);
  formData.append("images", images);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    dispatch(addProductData(formData));
    setProduct_name("");
    setProduct_price("");
    setProduct_description("");
    setRestauramtId("");
    setImages("");

    setTimeout(() => {
      navigate("/dashboard/show-products");
    }, 800);
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setImages(e.target.files[0]);
    }
  };

  return (
    <div className="dashboard">
      <form className="row g-3" onSubmit={handleOnSubmit}>
        <div className="col-md-12" style={{ textAlign: "center" }}>
          <h2>Add product</h2>
          <hr />
        </div>
        <div className="col-md-6">
          <label htmlFor="rest_name" className="form-label">
            Product Name
          </label>
          <input
            value={product_name}
            className="form-control"
            onChange={(e) => setProduct_name((pre) => e.target.value)}
            id="rest_name"
            required
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="rest_type" className="form-label">
            Product Price
          </label>
          <input
            value={product_price}
            onChange={(e) => setProduct_price((pre) => e.target.value)}
            type="number"
            className="form-control"
            id="rest_type"
            required
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="rest_desc" className="form-label">
            Product Decription
          </label>
          <input
            value={product_description}
            type="text"
            className="form-control"
            onChange={(e) => setProduct_description((pre) => e.target.value)}
            id="rest_desc"
            required
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="rest_id" className="form-label">
            Choose Your Restarunt
          </label>
          <select
            value={restauramtId}
            onChange={(e) => setRestauramtId((pre) => e.target.value)}
            className="form-select"
            id="rest_id"
            aria-label="Default select example"
            required
          >
            <option value="0">Select Restarunt</option>
            {renderSelect}
          </select>
        </div>

        <div className="col-md-12">
          <label htmlFor="res_img" className="form-label">
            Choose Image
          </label>
          <input
            required
            autoComplete="off"
            name="images"
            type="file"
            className="form-control"
            id="res_img"
            onChange={handleImageChange}
          />
          {images ? <img src={URL.createObjectURL(images)} alt=""  style={{width:'20vw'}}/> : ""}
        </div>
        <div className="col-12">
          <button type="submit" className="btn btn-primary">
            Add Product
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddMoreProduct;
