import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";   
import RestaurantList from "./Restaurants/RestaurantList";
import RestaurantFood from "./RestaurantFood/RestaurantFood";
import Cart from "./Cart/Cart";
import AddMoreProduct from "./Dashboard/AddMoreProduct/AddMoreProduct";
import OrderSuccess from "./OrderSuccess/OrderSuccess";
import Searchbar from "./Searchbar/Searchbar";
import PayNow from "./OrderSuccess/PayNow";
import LoginForm from "./Adminpanel/LoginForm";
import EmptyCart from "./Cart/EmptyCart";
import UserNotAuth from "./Adminpanel/UserNotAuth";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import UserResgistration from "./UserAuthantication/UserResgistration";
import UserLogin from "./UserAuthantication/UserLogin";
import AddUserAddress from "./Address/AddUserAddress";
import UserProfile from "./UserProfile/UserProfile";
import OrderDetails from "./OrderDetails/OrderDetails";
import EditAddress from "./Address/EditAddress";
// import PageNotFound404 from "./PageNotFound/PageNotFound404";
// import PastUserOrders from "./Order/PastUserOrders";


const Home = () => {
  const [sTerm, setSTerm] = useState("");
  const cartdata = useSelector((state) => state.cart.products);
  const showCart = useSelector((state) => state.cart.showCart);

  const cartSingleProduct = useSelector(
    (state) => state.cart.cartSingleProduct
  );
  return (
    <>
      {cartdata.length !== 0 && showCart ? (
        <Cart data={cartSingleProduct} />
      ) : cartdata.length === 0 && showCart ? (
        <EmptyCart />
      ) : (
        ""
      )}
      <ToastContainer theme="dark" />
      <div className="container">
        <Routes>
          <Route
            index
            path="/"
            element={
              <>
              <div className="row p-3 container">
                <Searchbar
                  placeHolder="Search food or resturant.."
                  setSTerm={setSTerm}
                />
                <RestaurantList sTerm={sTerm} />{" "}
                </div>
              </>
            }
          />
          <Route
            path="/food/:id"
            element={
              <>
                <Searchbar placeHolder="Search food.." setSTerm={setSTerm} />
                <RestaurantFood sTerm={sTerm} />{" "}
              </>
            }
          />
          <Route path="/add-cart/:productId" element={<Cart />} />
          <Route path="/add-product" element={<AddMoreProduct />} />
          <Route path="/create-address" element={<OrderSuccess />} />
          <Route path="/add-address" element={<AddUserAddress />} />
          <Route path="/user-profile" element={<UserProfile />} />
          <Route path="/oredr-confirn" element={<PayNow />} />
          <Route path="/order-details" element={<OrderDetails />} />
          <Route path="/admin/login" element={<LoginForm />} />
          <Route path="/user/login" element={<UserLogin />} />
          <Route path="/not-auth" element={<UserNotAuth />} />
          <Route path="/user/registration" element={<UserResgistration />} />
          <Route path="/update-user-address/:id" element={<EditAddress />} />
          {/* <Route path="/past-user-order" element={<PastUserOrders />} /> */}
          {/* <Route path="*" element={<PageNotFound404 />} /> */}
        </Routes> 
    </div>
    </>
  );
};

export default Home;
